@use '../../components/Color/style.scss' as color;

.page_occurrence {
    padding: .5rem;
    position: relative;
    width: 100%;
    z-index: 1;

    & .occurrence_filter_fields {
        display: grid;
        grid-template-columns: repeat(12, minmax(0px, 1fr));
        gap: .5rem;

        &>.field:has(>div:not(:first-child)) {
            @media screen and (max-width: 1450px) {
                grid-column: 6 span;
            }

            @media screen and (max-width: 900px) {
                grid-column: 8 span;
            }

            @media screen and (max-width: 400px) {
                display: block;

                &>*:not(:first-child) {
                    margin-top: .5rem;
                }
            }
        }

        &>.field {
            grid-column: 3 span;
            display: flex;
            gap: .5rem;

            &>* {
                width: 100%;
            }
        }

        &>.actions {
            grid-column: 11 / 2 span;

            &>div:has(>button[type=submit])>label {

                @media screen and (max-width: 899px) {
                    display: none;
                }
            }
        }

        @media screen and (max-width: 720px) {
            display: flex;
            flex-direction: column;
        }

        @media screen and (max-width: 900px) {

            &>.field {
                grid-column: 4 span;
            }
        }
    }

    &>.occurrence_container {
        background-color: white;
        box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, .5);
        border-radius: 2px;
        height: fit-content;
        margin-top: .5rem;

        &>.occurrences {

            &>.title {
                font-weight: 600;
                font-size: 18px;
                padding: .5rem .5rem .2rem .5rem;
            }

            &>.list {
                padding: .2rem;
                height: calc(100vh - 12rem);

                &>.table {
                    max-height: calc(100vh - 15.5rem);
                    overflow: auto;
                    margin-bottom: .5rem;
                }

                &>ul {
                    justify-content: end;
                }
            }
        }
    }
}